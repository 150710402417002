


































































































































import { DataTableHeader } from 'vuetify';
import { Component, Emit, Mixins } from 'vue-property-decorator';
import Avatar from '@/app/core/components/avatar.vue';
import UserDto, { Role } from '@/app/user/dto/user.dto';
import DatatableMixin from '@/app/core/mixins/datatable.mixin';
import RegisterUserDto from '@/app/users/dto/register-user.dto';
import DeletesUserMixin from '@/app/users/mixins/deletes-user.mixin';
import RegisterUserDialog from '@/app/users/components/register-user-dialog.vue';
import ResendsRegistrationEmailMixin from '@/app/users/mixins/resends-registration-email.mixin';
import AssignApartmentsPopover from './assign-apartments-popover.vue';

@Component({
  components: {
    Avatar,
    AssignApartmentsPopover,
  },
})
export default class UsersDatatable extends Mixins(
  DatatableMixin,
  DeletesUserMixin,
  ResendsRegistrationEmailMixin,
) {
  declare items: UserDto[];

  private headers: DataTableHeader[] = [
    {
      value: 'picture',
      text: this.$t('label.picture').toString(),
      class: 'visible-on-mobile-only',
    },
    { value: 'name', text: this.$t('label.name').toString() },
    { value: 'email', text: this.$t('label.email').toString() },
    { value: 'actions', text: this.$t('label.actions').toString() },
  ];

  get transformedItems() {
    return this.items.map((item: UserDto) => {
      const firstname = item.profile?.firstname;
      const lastname = item.profile?.lastname;

      return {
        id: item.id,
        picture: item.profile?.picture,
        role: item.role,
        name: (firstname && firstname) ? `${firstname} ${lastname}` : '',
        email: item.email,
        active: item.active,
      };
    });
  }

  async openRegisterDialog() {
    const success = await this.$dialog.open<RegisterUserDto>(
      RegisterUserDialog,
      { allowedRoles: [Role.HostAgent] },
    );

    if (success) this.userRegistered(success);
  }

  @Emit('userRegistered')
  userRegistered(dto: RegisterUserDto) {
    return dto;
  }
}
