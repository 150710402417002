




















































import { Component, Prop, Mixins, Watch } from 'vue-property-decorator';
import UserDto, { Role } from '@/app/user/dto/user.dto';
import ApartmentDto from '@/app/apartments/dto/apartment.dto';
import HandlesErrorMixin from '@/app/core/mixins/handles-error.mixin';
import apartmentsService, { getApartments } from '@/app/apartments/services/apartments.service';

@Component
export default class AssignApartmentPopover extends Mixins(HandlesErrorMixin) {
  loading = 0;
  saving = false;
  showPopover = false;
  apartments: ApartmentDto[] = [];
  assignedApartments: string[] = [];

  @Prop({ type: Object, required: true })
  user!: UserDto;

  get busy() {
    return this.loading > 0 || this.saving;
  }

  @Watch('showPopover', { immediate: true })
  loadData() {
    if (this.showPopover) {
      this.getAllApartments();
      this.getApartments();
    }
  }

  async getApartments() {
    this.loading += 1;

    try {
      const res = await this.getAssignedApartments();
      this.assignedApartments = res.data.items;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading -= 1;
    }
  }

  getAssignedApartments() {
    switch (this.user.role) {
      case Role.HostAgent:
        return apartmentsService.getAgentApartments(this.user.id);
      case Role.Owner:
        return apartmentsService.getOwnerApartments(this.user.id);
      default:
        throw Error('Role must be either HostAgent or Owner.');
    }
  }

  updateAssignedApartments() {
    switch (this.user.role) {
      case Role.HostAgent:
        return apartmentsService.updateAgentApartments(
          this.user.id,
          this.assignedApartments,
        );
      case Role.Owner:
        return apartmentsService.updateOwnerApartments(
          this.user.id,
          this.assignedApartments,
        );
      default:
        throw Error('Role must be either HostAgent or Owner.');
    }
  }

  async getAllApartments() {
    this.loading += 1;

    try {
      const res = await getApartments();
      this.apartments = res.data.items;
    } catch (err) {
      this.handleError(err);
    } finally {
      this.loading -= 1;
    }
  }

  async save() {
    this.saving = true;

    try {
      await this.updateAssignedApartments();
      this.showPopover = false;
      this.$notify.success(this.$t('success.assignedApartmentsUpdated').toString());
    } catch (err) {
      this.handleError(err);
    } finally {
      this.saving = false;
    }
  }
}
