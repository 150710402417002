var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-2 disable-sort",attrs:{"disable-sort":"","page":_vm.page,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.transformedItems,"items-per-page":_vm.itemsPerPage,"server-items-length":_vm.totalItems,"footer-props":{ 'items-per-page-options': _vm.itemsPerPageOptions }},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updateItemsPerPage},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce",value:(_vm.search),expression:"search"}],staticClass:"search-input",attrs:{"hide-details":"","color":"secondary","append-icon":"mdi-magnify","label":_vm.$t('label.search')}}),_c('v-spacer'),_c('v-btn',{staticClass:"d-flex d-sm-none mr-n3",attrs:{"icon":"","color":"secondary"}},[_c('v-icon',[_vm._v("mdi-account-plus")])],1),_c('v-btn',{staticClass:"d-none d-sm-flex",attrs:{"color":"secondary"},on:{"click":_vm.openRegisterDialog}},[_vm._v(" "+_vm._s(_vm.$t('btn.register'))+" ")])],1)]},proxy:true},{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('avatar',{attrs:{"size":"36","color":"white","iconColor":"secondary","userId":item.id,"picture":item.picture}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name)?_c('span',[_vm._v(_vm._s(item.name))]):_c('v-chip',{attrs:{"small":"","color":"error"}},[_c('v-icon',{staticClass:"mr-1 ml-n1",attrs:{"small":""}},[_vm._v(" mdi-alert-circle ")]),_vm._v(" "+_vm._s(_vm.$t('label.incompleteRegistration'))+" ")],1)]}},{key:"header.actions",fn:function(ref){
var header = ref.header;
return [_c('div',{staticClass:"text-center"},[_vm._v(" "+_vm._s(header.text)+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","disabled":!item.name,"color":item.active ? 'success' : 'error'}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(item.active ? 'mdi-account-check' : 'mdi-account-cancel')+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(item.active ? 'tooltip.deactivate' : 'tooltip.activate'))+" ")])]),(!item.active)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"info","loading":_vm.resendingRegistrationEmail.includes(item.id),"disabled":_vm.resendingRegistrationEmail.includes(item.id)},on:{"click":function($event){return _vm.resendRegistrationEmail(item.id)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-email-sync-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.resendRegistrationEmail'))+" ")])]):_vm._e(),_c('assign-apartments-popover',{attrs:{"user":item}}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error","loading":_vm.deletingUser.includes(item.id),"disabled":_vm.deletingUser.includes(item.id)},on:{"click":function($event){return _vm.deleteUser(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-account-remove-outline")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('tooltip.deleteAgent'))+" ")])])],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }